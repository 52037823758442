import { Injectable } from '@angular/core';
import { AvailableLangs, Translation, getBrowserLang } from '@ngneat/transloco';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseLanguageService } from './base-language.service';

@Injectable({
  providedIn: 'root',
})
export default class LanguageService extends BaseLanguageService {
  private readonly LANG_KEY = 'selectedLanguage';
  onLanguageChange$ = new BehaviorSubject('');

  init(): Promise<Translation> {
    let langCode: string | null = localStorage.getItem(this.LANG_KEY);

    if (!langCode) {
      langCode = navigator.language === 'tr' ? 'tr-TR' : 'en-US';
    }

    return this.setCurrentLanguage(langCode);
  }

  saveLanguageToLocalStorage(language: string) {
    localStorage.setItem(this.LANG_KEY, language);
  }

  getLanguageFromLocalStorage(): string | null {
    return localStorage.getItem(this.LANG_KEY);
  }

  getActiveLanguage(): string {
    return this.translocoService.getActiveLang();
  }

  getAvailableLanguages(): AvailableLangs {
    return this.translocoService.getAvailableLangs();
  }

  watchLanguageChanges(): Observable<string> {
    return this.translocoService.langChanges$;
  }

  getBrowserLanguage(): string | undefined {
    return getBrowserLang();
  }

  override async setCurrentLanguage(language: string): Promise<Translation> {
    const result = await super.setCurrentLanguage(language);
    this.onLanguageChange$.next(language);
    return result;
  }
}
